import './App.css';
import Form from "./form";
import logo from "./img/logo.png";

function App() {
  return (
    <div className="App">
      <div className="main-box">
        <div className="inner">
          <img src={logo} className="logo" alt="logo"></img>
          <hr></hr>
          <h2>- Links -</h2>
          <ul className="buttons">
            <li>
              <a class="button" href="https://www.facebook.com/aigeetravelservices">
                <span>Facebook</span>
              </a>
            </li>
             <li>
              <a class="button" href="https://www.instagram.com/aigeetravels">
                <span>Instagram</span>
              </a>
            </li>
             <li>
              <a class="button" href="https://www.tiktok.com/@aigeetravels">
                <span>Tiktok</span>
              </a>
            </li>
          </ul>
          <hr></hr>
          <h2>- Contact us -</h2>
          <Form />
        </div>
      </div>
    </div>
  );
}

export default App;
